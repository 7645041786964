.login-container {
  display: block;
  padding: 0 35%;
  overflow: auto;
}

@media (max-width: 758px) {
  .login-container {
    padding: 0 10%;
  }
}

.custom-input {
  display: block;
  border: 0.5px solid #cfd0da;
  width: 100%;
  margin: 0.8rem 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 13px 9px;
  border-radius: 2px;
}

.input-container {
  margin: 1.5em 0;
  width: 100%;
}

.generic-button {
  background-color: #02073e;
  color: #fff;
  width: 100%;
  padding: 16px 10px;
  border: none;
  cursor: pointer;
}

.login-form--spanText {
  display: block;
  margin: 20px 0;
}
