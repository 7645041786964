@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

body {
  /* font-family: "Roboto", sans-serif; */
}

.semibold {
  font-weight: 500;
}

.font-semibold {
  /* font-weight: 500; */
}
